<template>
  <div class="create-apiKey">
    <CForm
        ref="createCollaborator"
        novalidate
        class="create-action-form"
    >
      <div class="form-content">
        <div class="mb-4 mr-2 bread-content">
          <span>1.Message</span>
        </div>
        <!--    PAGE TOOLBAR    -->
        <ClPageToolbar
            isAdditionalParam
            @toggleAdditionalParam="handleToggleAdditionalParam"
            @toggleEditorToolbar="handleToggleEditorToolbar" />

        <!--     ID   -->
        <ClFormRow label="ID" tool-tip-text="Collaborator Id">
          <template #content>
            <CInput
                placeholder
                v-model="formData.id"
                add-input-classes="col-sm-12"
                disabled="true"
            />
          </template>
        </ClFormRow>
        <!--   Created   -->
        <ClFormRow label="Created" tool-tip-text="Created">
          <template #content>
            <CInput
                :value="created"
                add-input-classes="col-sm-12"
                required
                disabled="true"
            />
          </template>
        </ClFormRow>
        <!--      -->
        <ClFormRow label="Space Name" tool-tip-text="Space Name">
          <template #content>
            <CInput
                :value="formData.spaceName"
                add-input-classes="col-sm-12"
                required
                disabled="true"
            />
          </template>
        </ClFormRow>
        <!--   MEMBERS GROUP     -->
        <ClFormRow
            label="Member Groups"
            tool-tip-text="A list of tag models of member groups to which the message will be sent">
          <template #content>
            <multiselect
                v-model="formData.memberGroup"
                :options="options"
                :multiple="true"
                :preserve-search="true"
                :taggable="true"
                :hide-selected="true"
                @tag="addGroups"
                :selectLabel="selectLabel"
                :tagPlaceholder="tagPlaceholder"
                class="custom-multi-select zq--multiselect"
            >
              <template #caret>
                <div></div>
              </template>
            </multiselect>
            <img
                src="../../../../../assets/icons/search.svg"
                width="16"
                height="16"
                alt="search"
                class="zq--multiselect-search-icon">
          </template>
        </ClFormRow>
        <!--        -->
        <ClFormRow label="Members*" tool-tip-text="A list of member id's to which the message will be sent">
          <template #content>
            <multiselect
                v-model="formData.members"
                :options="memberOptions"
                :multiple="true"
                :preserve-search="true"
                :hide-selected="true"
                :taggable="true"
                @tag="addMember"
                :selectLabel="selectLabel"
                :searchable="false"
                :block-keys="['Tab', 'Enter']"
                :class="selectErrorClass"
                class="custom-multi-select zq--multiselect"
            >
              <template #caret>
                <div></div>
              </template>
            </multiselect>
            <img
                src="../../../../../assets/icons/search.svg"
                width="16"
                height="16"
                alt="search"
                class="zq--multiselect-search-icon">
          </template>
        </ClFormRow>
        <!--    Message Type    -->
        <ClFormRow label="Message Type*" tool-tip-text="Message Type">
          <template #content>
            <ClSelect
                v-if="formData.messageType"
                class="mb-2 text-right m-0 text-capitalize"
                :options="messageTypesOptions"
                :valueProp="formData.messageType"
                size="xxl"
                :selectLabel="selectLabel"
                :is-valid="typeValidate"
                placeholder="Select message type"
                @checkValue="messageTypeUpdate" />
          </template>
        </ClFormRow>
        <!--  -->
        <ClFormRow label="Subject*" tool-tip-text="The title of the message">
          <template #content>
            <CInput
                placeholder="Subject"
                v-model="formData.subject"
                add-input-classes="col-sm-12"
                required
                invalidFeedback="Required"
                :is-valid="isSubject"
            />
          </template>
        </ClFormRow>
        <!--   BODY    -->
        <ClFormRow label="Body*" tool-tip-text="The body of the message">
          <template #content>
            <ClEditor
                :is-toolbar="isToolbar"
                @change="onBodyChange"
                placeholder="Enter your message here"
                :value-prop="formData.body"
                :options="bodyOptions"
                :is-valid="bodyValidate" />
          </template>
        </ClFormRow>
        <!--    Additional Param    -->

        <!--        -->
        <div v-show="showParam">
          <!--     Tags     -->
          <ClFormRow label="Tags" tool-tip-text="Tags">
            <template #content>
              <multiselect
                  v-model="formData.tags"
                  :options="tagOptions"
                  :multiple="true"
                  :preserve-search="true"
                  :taggable="true"
                  :hide-selected="true"
                  :selectLabel="selectLabel"
                  :tagPlaceholder="tagPlaceholder"
                  @tag="addTags"
                  class="custom-multi-select zq--multiselect"
              >
                <template #caret>
                  <div></div>
                </template>
              </multiselect>
              <img
                  src="../../../../../assets/icons/search.svg"
                  width="16"
                  height="16"
                  alt="search"
                  class="zq--multiselect-search-icon">
            </template>
          </ClFormRow>
          <!--    METADATA    -->
          <ClFormRow
              label="Metadata"
              tooltipPosition="label"
              xl-label="12"
              md-content="12"
              xl-content="12">
            <template #content>
              <CMetaInputs
                  v-on:change="addMeta"
                  :fields="formData.metadata" />
            </template>
          </ClFormRow>
        </div>
      </div>
    </CForm>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import {cloneDeep} from 'lodash';

import CMetaInputs from '@/components/forms/CMetaInputs';
import routerBreadcrumbs from '@/router/breadcrumb/routerBreadcrumbs';
import {formConfig, pageConfig} from "@/config";
import {dateFormate} from "@/utils";
import ClFormRow from "@/shared/components/formComponents/ClFormRow";
import ClSelect from "@/shared/components/formComponents/ClSelect";
import ClEditor from "@/shared/components/formComponents/ClEditor";
import ClPageToolbar from "@/shared/components/ClPageToolbar";


export default {
  name: 'EditDraftMessage',
  components: {
    CMetaInputs,
    ClFormRow,
    ClEditor,
    ClPageToolbar,
    ClSelect,
  },
  data() {
    return {
      selectLabel: formConfig.selectLabel,
      tagPlaceholder: formConfig.tagPlaceholder,
      formData: {
        memberGroup: [],
        members: [],
        messageType: '',
        subject: '',
        body: '',
        status: '',
        translatableFields: [],
        constraints: '',
        scheduling: '',
        translations: '',
        metadata: [],
        tags: [],
      },
      options: ['group1', 'group2', 'group3'],
      itemsPerPage: pageConfig.itemsPerPage,
      sortBy: pageConfig.sortBy,
      page: pageConfig.page,
      columnFilter: false,
      bodyOptions: {
        placeholder: 'Enter your message here',
      },
      showParam: false,
      memberOptions: ["Dc4swmQBVd51K6gPQqFx", "SHvkX24BQudJDGCuEtOH"],
      placeholders: {key: 'Key', value: 'Value'},
      tagOptions: ['tag1', 'tag2', 'tag3'],
      messageTypesOptions: ['Achievement', 'Ticket', 'Reward', 'Text', 'Notification', 'InboxItem', 'NotificationInboxItem'],

      typeValidate: null,
      bodyValidate: null,
      memberValidate: null,
      initState: false,
      isToolbar: false
    };
  },
  props: {
    messageData: Object,
    isSubject: Boolean,
    isType: Boolean,
    isMember: Boolean,
    isBody: Boolean
  },

  computed: {
    ...mapGetters('messages', ['success', 'messages', 'loading', 'singleMessage']),
    ...mapGetters('theme', ['theme']),
    selectErrorClass() {
      const className = this.theme === 'main' ? 'error-validation--multiselect' : 'error-validation';
      return {
        [className]: (this.memberValidate === false)
      }
    },
    created() {
      return dateFormate(this.formData.created);
    },
  },
  created() {
    this.query = {"id": this.$route.params.id};
    this.handleGetMessageById({
      queryData: this.query,
      sortBy: this.sortBy,
      skip: (this.page - 1) * this.itemsPerPage,
      limit: this.itemsPerPage,
    }).then(data => {

      routerBreadcrumbs(this.$router.currentRoute, {
        name: data.id
      });
    });
  },
  watch: {
    formData: {
      deep: true,
      handler: function (val) {
        if (val.subject) this.subjectValidate = true;
        if (val.messageType) this.typeValidate = true;
        if (val.body) this.bodyValidate = true;
        if (val.members.length) {
          this.memberValidate = null;
          this.$emit('resetValidate');
        }
        this.$emit('updateMessageData', val);
      },
    },
    singleMessage: {
      deep: true,
      handler: function (item) {
        this.formData = cloneDeep(item);
      }
    },
    isType(val) {
      this.typeValidate = val;
    },
    isMember(val) {
      this.memberValidate = val;
    },
    isBody(val) {
      this.bodyValidate = val;
    }
  },
  methods: {
    ...mapActions('messages', ['handleGetMessageById', 'handleUpdateMessages']),
    initialize() {
      this.constraints = this.constraintsProps;
      this.formData = this.messageData;
      //this.updateConstraints(this.initState);
    },
    onChangeType(val) {
      this.formData.messageType = val;
      this.typeValidate = true;
    },

    messageTypeUpdate(val) {
      this.formData.messageType = val;
    },
    onBodyChange(html) {
      this.formData.body = html;
    },
    addTags(newTag) {
      this.tagOptions.push(newTag);
      this.formData.tags.push(newTag);
    },
    addGroups(newGroup) {
      this.options.push(newGroup);
      this.formData.memberGroup.push(newGroup);
    },
    addMember(newMember) {
      this.memberOptions.push(newMember);
      this.formData.members.push(newMember);
    },
    addMeta(meta) {
      this.formData.metadata = meta;
    },
    onChange(value) {
      this.formData.memberGroups = value;
    },
    goBack() {
      this.$router.go(-1);
    },
    handleToggleEditorToolbar(val) {
      this.isToolbar = val;
    },
    handleToggleAdditionalParam(val) {
      this.showParam = val;
    }
  },
  destroyed() {
    this.$emit('resetValidate');
  }
};
</script>
<style lang="scss">
.create-apiKey {
  height: 100%;

  .create-action-form {
    height: calc(100% - 50px);

    .form-content {
      background: var(--zq-sub-bg);
      height: 100%;

    }
  }

  #published-que {
    top: 6px;
    right: -20px;
  }
}
</style>