<template>
  <div class="achievements-form">
    <!--   Header   -->
    <div>
      <CRow>
        <CCol col="12" sm="6" class="d-flex align-items-center">
          <div class="zq-page-title-wrapper d-flex">
        <h3 class="zq-page-title">Edit Message Steps</h3>
            <IconWithTooltip class="zq--header-tooltip" :text="descriptions.pageTitle"/>
          </div>
        </CCol>
        <CCol col="12" sm="6">
          <ActionCreateBtns
              class="pt-3"
              :currentStep="currentStep"
              :totalStep="totalStep"
              @updateCurrentStep="updateCurrentStep"
              :finishAction="EditMsg"
              v-on:next-step="nextStep"
          />
        </CCol>
      </CRow>
      <NewAchievementHeader
          @updateCurrentStep="updateCurrentStep"
          :currentStep="currentStep"
          :steps="steps"
      />
    </div>
    <!-- Content   -->
    <div class="content">
      <!--  Create Message Step  -->
      <EditDraftMessage
          @updateMessageData="getMessageData"
          @resetValidate="resetValidate"
          @updateConstraints="getConstraints"
          :constraintsProps="constraints"
          :messageData="messageData"
          v-if="currentStep === 0"
          :isSubject="messageDataValid_subject"
          :isType="messageDataValid_messageType"
          :isBody="messageDataValid_body"
          :isMember="messageDataValid_members"
      />

      <!--   Translations Step    -->
      <TranslationsStep
          v-if="currentStep === 1"
          :messageData="messageData"
          :translationsData="translationsData"
          @updateTranslationsData="getTranslationsData"
      />
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ActionCreateBtns from '@/shared/components/steps/ActionCreateBtns';
import NewAchievementHeader from '../../../../../shared/components/steps/Header';
import {dateUTC} from '@/utils/dateUTC';
import routerBreadcrumbs from "@/router/breadcrumb/routerBreadcrumbs";

import EditDraftMessage from './EditDraftMessage';
import TranslationsStep from './TranslationsStep';
import {translationsTransform} from "@/utils/translationsUtils";

import IconWithTooltip from '@/shared/UI/IconWithTooltip';
import {messaging} from "@/config/descriptions/messaging"

export default {
  name: 'EditMessage',
  components: {
    ActionCreateBtns,
    NewAchievementHeader,
    EditDraftMessage,
    TranslationsStep,
    IconWithTooltip
  },
  data() {
    return {
      descriptions: {
        ...messaging.draft.edit
      },
      currentStep: 0,
      totalStep: 1,
      constraints: [],
      messageData: {
        subject: '',
        body: '',
        metadata: [],
        tags: [],
        members: [],
        memberGroups: [],
        messageType: '',
        scheduling: '',
      },
      translationsData: {
        "German": {"subject": "Herzliche Glückwünsche!", "body": "<p>Dies ist eine Testnachricht</p>"},
        "Spanish": {"subject": "¡Felicidades!", "body": "<p>Este es un mensaje de prueba</p>"},
        "Italian": {"subject": "Congratulazioni!", "body": "<p>Questo è il messaggio di prova</p>"},
        "Russian": {"subject": "Поздравляю!", "body": "<p>Это тестовое сообщение</p>"}
      },

      steps: [
        {
          title: 'Message',
          subTitle: 'Member, message Type, Body',
        },
        {
          title: 'Translations',
          subTitle: 'Define available translations',
        },
      ],
      messageDataValid_subject: null,
      messageDataValid_body: null,
      messageDataValid_members: null,
      messageDataValid_messageType: null,
    }
  },
  computed: {
    previewFileBreadcrubs() {
      if (this.currentPage.data.from) {
        return ['EditDraftMessage']
      } else {
        return ['EditDraftMessage', 'TranslationsStep']
      }
    }
  },
  methods: {
    ...mapActions('messages', ['handleGetMessageById', 'handleUpdateMessages']),

    initialize() {

    },
    updateCurrentStep(val) {
      this.currentStep = val;
    },
    getConstraints(val) {
      this.constraints = val;
    },
    getMessageData(val) {
      this.messageData = val;
      this.$emit('getMessageData', val);
    },
    getTranslationsData(val) {
      this.translationsData = val;
    },
    resetValidate() {
      this.messageDataValid_subject = null;
      this.messageDataValid_messageType = null;
      this.messageDataValid_members = null;
      this.messageDataValid_subject = null;
    },

    validation(data, fields) {
      const validationArray = fields.map((item) => {
        const validationField = `${data}Valid_${item}`
        if (!this[data][item]) {
          this[validationField] = !!this[data][item];
          return false
        } else {
          return true
        }
      });
      return !validationArray.includes(false)
    },
    memberValidate(val) {
      if (!val) this.$emit('resetValidate');
    },
    checkMembers() {
      if (this.messageData.members.length === 0) {
        this.messageDataValid_members = false;
        return false;
      } else {
        this.messageDataValid_members = null;
        return true;
      }
    },
    nextStep() {
      console.log(this.messageData)
      if (this.currentStep === 0 && (this.validation('messageData', ['subject', 'messageType', 'body'])) && this.checkMembers()) {
        this.currentStep = this.currentStep + 1;
      }
    },
    EditMsg() {
      const body = [{
        // BODY FROM SETTINGS STEP
        subject: this.messageData.subject,
        body: this.messageData.body,
        metadata: this.messageData.metadata,
        tags: this.messageData.tags,
        members: this.messageData.members,
        messageType: this.messageData.messageType,
        memberGroups: this.messageData.memberGroups,
        scheduling: this.messageData.scheduling,
        constraints: this.messageData.constraints,

        // BODY FROM Translations STEP
        translations: translationsTransform(this.translationsData),

      }]
      this.handleUpdateMessages(body);
    },
  },
  created() {
    this.initialize();
  },
}
</script>

<style lang="scss">
.achievements-form {
  display: flex;
  flex-direction: column;
  height: 100%;

  .bread-content {
    font-size: 1.53125rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
  }

  .wizard-action-btn {
    position: relative;
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content {
    height: 100%;
    flex: 2;
    background: var(--zq-sub-bg);

    .form-group {
      .was-validated, .form-control:valid, .form-control.is-valid {
        padding-right: 5px;

      }
    }


    /*.error-validation {
      .multiselect__tags {
        border: 1px solid #e55353;
        padding: 8px 50px 0 8px;

        &:before {
          position: absolute;
          content: "";
          right: 2rem;
          top: 1em;
          width: 18px;
          height: 18px;
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e55353' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e55353' stroke='none'/%3e%3c/svg%3e");
          background-repeat: no-repeat;
          background-size: cover;
        }

        &:after {
          content: 'Required';
          position: absolute;
          top: 4.5em;
          left: 0;
          font-size: 80%;
          color: #e55353;
        }
      }
    }*/

  }


}
</style>